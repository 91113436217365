<template>
  <div class="flex-row">
    <div class="profile-wrapper profile-wrapper-skeleton" v-if="isLoading">
      <mu-circular-progress class="demo-circular-progress" :size="26">
      </mu-circular-progress>
    </div>
    <div class="profile-wrapper" v-if="list && !isLoading">
      <div v-for="(item, key) in (useFullList ? fullList : list.List)" :key="key">
        <div v-if="Number(item.id) === contact_id">
          <contact-head-nav
              :leftNav="false"
              :back="back"
              :back-label="backLabel"
              @promoFavoriteContact="toggleFavFromContactHeadNav"
              @promoContactGoToMessages="navSelect('messages')"
              :isFavoriteContact="item.is_favorite"
              :video_stream_url="item.video_stream_url"
          ></contact-head-nav>
          <div class="video-conf"></div>
          <div class="profile">
            <div class="head-block">
              <div class="avatar avatar-headblock round-radius">
                <img :src="setAvatar(item.photo_url)" alt=""/>
              </div>
              <div class="head-content">
                <div class="title">
                  <span v-if="item.name">{{ item.name + ' ' }}</span>
                  <span v-if="item.surname">{{ item.surname }}</span>
                  <span v-if="!item.name && !item.surname"> {{$t('contacts.info.noname')}} </span>
                </div>
                <div class="subtitle">{{ item.company_name }}</div>
                <div class="subtitle">{{ item.company_position }}</div>
                <div class="subtitle">{{ item.city }} {{ item.country }}</div>
                <div class="info"></div>
              </div>
            </div>
            <div class="nav">
              <span
                v-if="!hideProfile"
                  class="tab cur-p"
                  :class="{ highlight: navSelected === 'profile' }"
                  @click="navSelect('profile')"
              >{{ $t("title.profile") }}</span>
              <span
                  class="tab cur-p"
                  :class="{ highlight: navSelected === 'calendar' }"
                  @click="navSelect('calendar')"
              >{{ $t("title.calendar") }}</span>
              <span
                  class="tab cur-p"
                  :class="{ highlight: navSelected === 'messages' }"
                  @click="navSelect('messages')"
              >{{ $t("title.messages") }}</span>
              <span
                  class="tab cur-p"
                  :class="{ highlight: navSelected === 'notes' }"
                  @click="navSelect('notes')"
              >{{ $t("title.notes") }}</span>
            </div>
            <div class="content">
              <div class="description">
              <span v-if="item.company_description && navSelected === 'profile'">
                <truncate
                    action-class="read-more"
                    :length="90"
                    :less="$t('button.less')"
                    :clamp="$t('button.more')"
                    :text="item.company_description"
                ></truncate>
              </span>
              </div>
            </div>
          </div>

          <!-- Documents -->
          <document-list
              class="field-block field-block-documents"
              :class="{
                'show-action': !showDocuments,
                'show-all': showDocuments
              }"
              :docs="item.files"
              :showAll="showDocuments"
              @seeAllDocuments="showAllDocuments()"
              v-if="item.files && item.files.length && (navSelected === 'profile' || navSelected === 'documents')"
          ></document-list>


          <!-- Notes -->
          <notes-list
              class="field-block field-block-notes"
              v-if="noteList && noteList.length && (navSelected === 'profile' || navSelected === 'notes')"
              :notes="noteList"
              :showAll="navSelected === 'notes'"
              :showActionButton="navSelected !== 'notes'"
              @seeAllNotes="navSelect('notes')"
          ></notes-list>


          <div class="field-block"
               v-if="navSelected === 'calendar'"
          >
            <div class="field-header">
              <div class="field-title">{{$t('title.calendar')}}</div>
            </div>
            <div class="field-content field-content-meetings">
              <contact-calendar
                  :userId="user_id"
                  :contactData="item"
                  :promoPage="promoPage"
              ></contact-calendar>
            </div>
          </div>


          <div v-if="navSelected === 'messages'">
            <div class="field-block">
              <div class="field-header">
                <div class="field-title">{{$t('title.messages')}}</div>
              </div>
            </div>
            <perfect-scrollbar @ps-y-reach-start="loadMore"
                               class="scroll-height"
                               id="container"
                               ref="msgContainer"

            >
              <div class="field-block" v-if="navSelected === 'messages'" style="padding: 0 25px;">
                <div
                    class="messages"
                    v-for="(message, key) in messageList"
                    :key="key"
                >
                  <div
                      class="content-text"
                      :class="{
                        'my-message': message.is_mine,
                        'their-message': !message.is_mine,
                      }"
                  >
                    <div class="date_text">
                      {{ addOffset(message.created_at).format('lll') }}
                    </div>
                    <div class="text_title" v-if="message.contact">
                      <div class="sender-name" v-if="message.is_mine">{{ contact.name }} {{ contact.surname }}</div>
                      <div class="sender-name" v-else>{{ message.contact.name }} {{ message.contact.surname }}</div>
                      <div class="sender-avatar"
                           :style="{ 'background-image' : setMessageSenderAvatar(message) }"></div>
                    </div>
                    <div class="text" v-if="message.text">
                      <truncate
                          action-class="more-btn"
                          :length="90"
                          :less="$t('button.less')"
                          :clamp="$t('button.more')"
                          :text="message.text"
                      ></truncate>
                    </div>
                    <!--              <a href="javascript:void(0)"-->
                    <!--                 @click="showMore(key)">more…</a>-->
                  </div>
                </div>
                <div v-if="messagesLoading" class="inner-circular-progress">
                  <mu-circular-progress :size="26"></mu-circular-progress>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
          <div class="field-block" v-if="navSelected === 'messages'">
            <div class="field-block">
              <div class="field-header">
                <div class="field-title">{{$t('title.messagesSend')}}</div>
              </div>
            </div>
            <div class="field-content messages">
              <div class="content-text messages-text">
                <mu-text-field
                    v-model="messageText"
                    class="text-input"
                    full-width
                    @keyup.ctrl.enter="sendMessage()"
                    multi-line
                    :rows="5"
                    :placeholder="$t('title.type_your_message')"
                ></mu-text-field>
                <mu-button class="send-btn"
                           small
                           round
                           @click="sendMessage()"
                >{{ $t("button.send") }}
                </mu-button>
                <div class="send-btn-keycombo">{{ $t('button.ctrlEnter') }}</div>
              </div>
            </div>
          </div>
<!--          <div class="field-block" v-if="navSelected === 'messages' && !isShowMessageForm">-->
<!--            <div class="field-header">-->
<!--              <div class="field-title">{{$t('title.msg_will_available_on_event_dates')}}</div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
    <router-view
      class="contact-company-wrapper"
      name="b"></router-view>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import contactHeadNav from './contact-head-nav';
import truncate from 'vue-truncate-collapsed';
import ContactCalendar from './contactCalendar';
import { DateTimeHelper } from '../../../../helpers/date-time.helper';
import { FileNameHelper } from '../../../../helpers/filename.helper';
import DocumentList from '../documents/documentList';
import NotesList from '../notes/notesList';
import Statistics from '@/services/statistics';

const beforeRouteEnter = { to: null, from: null };

export default {
  name: 'promo-contact',
  components: {
    ContactCalendar,
    contactHeadNav,
    truncate,
    DocumentList,
    NotesList
  },
  props: {
    hideProfile: {
      type: Boolean,
      default: false
    },
    back: {
      type: Object,
    },
    backLabel: {
      type: String,
    },
    useFullList: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      contactList: state => state.contactStore.contactList,
      contactListType: state => state.promoStore.contactListType,
      promoPage: state => state.promoStore.promoPage,
      navigationTab: state => state.promoStore.navigationTab,
      noteListAll: state => state.noteStore.noteList,
      // messageListAll: state => state.messageStore.messageList,
      eventInfo: state => state.eventStore.eventInfo,
      contactListLoading: state => state.contactStore.contactListLoading,
    }),
    ...mapGetters('_badgeNotificationsStore', {
      messages_count: 'getMessagesCount'
    }),
    ...mapGetters('_messageStore', {
      messageListAll: 'userMessages',
      messagesLoading: 'userMessagesIsLoading',
    }),
    ...mapGetters('promoPageStore', {
      contact: 'contact',
    }),
  },
  watch: {
    navigationTab:{
      immediate: truncate,
      handler(){
        this.navSelect(this.navigationTab)
      }
    },
    '$route.params.contact_id': {
      deep: true,
      handler() {
        this.contact_id = Number(this.$route.params.contact_id);

        this.list = this.contactList;
        this.isContactListLauncher(this.list.List)
      }
    },
    contactList: {
      deep: true,
      handler() {
        this.list = this.contactList;
        this.fullList = [];
        if (this.list && this.list.List && this.list.List.length) {
          this.list.List.forEach(contact => this.fullList.push(contact));
        }
        if (this.contact) {
          this.fullList.push(this.contact);
        }
        this.isContactListLauncher(this.list.List)
      }
    },
    messages_count: {
      handler() {
        this.setMessages();
      }
    },
    promoPage: {
      deep: true,
      handler() {
        this.external_id = this.promoPage.external_id;
        if (
          this.external_id
          && this.$route.name !== 'promo-page-calendar-contact'
          && this.$route.name !== 'promo-program-date-program-contact'
        ) {
          this.$router.push({name: 'promo-contact-company', params: {external_id: this.external_id}}).catch(e => e);
        }
      }
    },
    messageListAll: {
      deep: true,
      handler() {
        this.messageList = this.messageListAll.List;
        this.enableMessages()
      }
    },
    contact_id: {
      deep: true,
      handler() {

        Statistics.contactView({
          eventId: this.event_id,
          contactId: this.contact_id,
        }, beforeRouteEnter);

        if (this.list) {
          this.list.List.forEach((item) => {
            if (item.id) {
              if (item.id === this.contact_id) {
                this.isFavoriteContact = item.is_favorite;
              }
            }
          });
          this.showContactCompany(this.contact_id);
        }
      }
    },
    user_id: {
      handler() {
        this.setNote();
      }
    },
    isShowNotes: {
      handler() {
        this.showNotes = !this.isShowNotes;
      }
    },
    isShowDocuments: {
      handler() {
        this.showDocuments = !this.isShowDocuments;
      }
    },
    isShowMeetings: {
      handler() {
        this.showMeetings = !this.isShowMeetings;
      }
    },
    contactListLoading: {
      handler() {
        this.isLoading = this.contactListLoading;
      }
    }
  },
  data() {
    return {
      fullList: [],
      list: null,
      noteFav: true,
      leftNav: false,
      event_id: null,
      external_id: null,
      contact_id: null,
      noteList: [],
      messageList: {},
      user_id: null,
      showDocuments: false,
      showNotes: false,
      isFavoriteContact: false,
      showMoreKey: null,

      navSelected: 'profile',
      messageText: '',

      limit: 20,

      loadMessagesInterval: null,
      isShowMessageForm: false,
      isLoading: false,
    };
  },

  beforeRouteEnter(to, from, next) {
    beforeRouteEnter.to = to;
    beforeRouteEnter.from = from;
    next();
  },

  created() {
    if (this.hideProfile) {
      this.navSelected = 'messages';
    }
    this.event_id = Number(this.$route.params.eventId);
    this.external_id = this.$route.params.external_id;
    this.contact_id = Number(this.$route.params.contact_id);

    // Checking contactListType so that the unfolded contact list uses All, My or My messages modes
    let actionName = 'callContactListAll';
    if (this.contactListType === "own") {
      actionName = 'callContactListMy';
    }
    if (this.contactListType === "bymessages") {
      actionName = 'callContactListByMessages';
    }
    // This action call is needed for when the page has been opened by a URL.
    // TODO: request optimization — try not calling this list if we already have it in the store
    this.$store.dispatch('contactStore/' + actionName, {event_id: this.event_id});

    if (this.contact_id) {
      this.showContactCompany(this.contact_id);
    }
  },
  updated() {

    this.event_id = Number(this.$route.params.eventId);
    this.external_id = this.$route.params.external_id
    this.contact_id = Number(this.$route.params.contact_id);

  },
  mounted() {
    this.setMessages();
  },
  beforeDestroy() {
    this.$store.dispatch('promoStore/setNavigationTab', 'profile');
  },
  methods: {
    addOffset(dateTimeString) {
      return DateTimeHelper.getMomentWithOffset(dateTimeString);
    },
    setMessageSenderAvatar(message) {
      // If the message is is_mine, take data from contact entity
      // otherwise from message.contact
      if (message.is_mine && this.contact && this.contact.photo_url) {
        return 'url(' + this.contact.photo_url + ')';
      } else if (message && message.contact && message.contact.photo_url) {
        return 'url(' + message.contact.photo_url + ')';
      }
      // fallback to default avatar
      return 'url(' + require('../../../../assets/images/no-avatar-300x300.png') + ')';
    },
    showContactCompany(contact_id) {
      if (contact_id) {
        this.isLoading = true;
        Promise.all([
          this.$store.dispatch("promoStore/getPromoPageContactId", {
            event_id: this.$route.params.eventId,
            contact_id: contact_id
          })
        ]).then(() => {
          this.isLoading = false;
        })
      }
    },
    docName(val) {
      return FileNameHelper.getFileName(val);
    },
    docType(val) {
      return FileNameHelper.getFileExtension(val);
    },
    setNote() {
      Promise.all([
        this.$store.dispatch('noteStore/callNoteListAll',
          {
            event_id: this.event_id,
            user_id: this.user_id
          })
      ]).then(() => {
        if (this.noteListAll && this.noteListAll.List) {
          this.noteList = this.noteListAll.List
        }
      });
    },
    async setMessages() {
      if (!this.event_id || !this.user_id) {return}

      await this.$store.dispatch('_messageStore/callUserMessageList', {
        eventId: this.event_id,
        userId: this.user_id
      })


     if (this.messageListAll && this.messageListAll.List) {
        this.messageList = this.messageListAll.List;
      }

      if (this.messageList && !this.messagesLoading) {
        const container =  await this.$el.querySelector("#container");
        if (container) {
          if (this.limit <= 5) {
            container.scrollTop = 200;
          } else {
            container.scrollTop = container.scrollHeight;
          }
        }
      }

     // this.$store.dispatch('_badgeNotificationsStore/getBadgeNotificationsCount', Number(this.$route.params.eventId));
    },
    async sendMessage() {
      if (this.messageText && this.messageText.trim()) {

        await this.$store.dispatch('_messageStore/sendMessage', {
          eventId: this.event_id,
          userId: this.user_id,
          text: this.messageText.trim()
        })

        await this.setMessages();
        this.messageText = '';
      }
    },
    setAvatar(url) {
      if (url) {
        return url
      } else {
        let noPhoto = require('../../../../assets/images/no-avatar-300x300.png');
        return noPhoto;
      }
    },
    showAllDocuments() {
      this.showDocuments = !this.showDocuments
    },
    showAllNotes() {
      this.showNotes = !this.showNotes
    },
    toggleFavFromContactHeadNav(bool) {

      this.isFavoriteContact = bool;

      if (bool === false) {
        let payload = {
          event_id: this.event_id,
          contact_id: this.contact_id,
        };
        Promise.all([
          this.$store.dispatch('contactStore/removeFavContact', payload)
        ]).then(() => {
          // this.$emit('promoCallFavCompany', bool)
        })
      }
      if (bool === true) {
        let payload = {
          event_id: this.event_id,
          contact_id: this.contact_id
        };
        Promise.all([
          this.$store.dispatch('contactStore/addFavContact', payload)
        ]).then(() => {
          // this.$emit('promoCallFavCompany', bool)
        })
      }

    },
    showMore(key) {
      this.showMoreKey = key;
      return true;
    },
    navSelect(value) {
      this.navSelected = value || 'profile';
      this.$emit('closePromoOthers');

      if (this.navSelected === 'messages') {
        this.$nextTick(() => {
          if (!this.$refs["msgContainer"]) {return}
          let msgContainer = this.$refs["msgContainer"][0].$el;
          if (msgContainer) {
            if (this.limit <= 5) {
              msgContainer.scrollTop = 200;
            } else {
              msgContainer.scrollTop = msgContainer.scrollHeight;
            }
          }
        })
      }
    },
    loadMore() {
      if (!this.messagesLoading) {
        if (this.limit <= this.messageListAll.Total) {
          this.limit += 20;
          this.setMessages();
        }
      }
    },
    enableMessages() {
      const dateStart = this.$moment.utc(this.eventInfo.date_start);
      this.isShowMessageForm = this.$moment().isSameOrAfter(dateStart, 'minutes');
    },
    isContactListLauncher(list) {
      if (!list) { return }

      for (let i = 0; i < list.length; i++) {
        if (list[i].id === this.contact_id) {
          this.user_id = list[i].user.id;
          this.isFavoriteContact = list[i].is_favorite;
          break;
        }
      }

      if (this.user_id) {
        this.setNote();
        this.setMessages()
      }

    }
  },
};
</script>

<style scoped lang="scss">
  .contact-company-wrapper {
    width: calc(100% - 410px); // AW-734 100% - ширина колонки с профилем - щель между колонками
    min-width: 392px; // AW-734
    max-width: 870px; // AW-850
  }

  .scroll-height {
    max-height: 350px;
    padding-bottom: 30px;
  }

  .flex-row {
    display: flex;
    justify-content: flex-start;
    flex-flow: row nowrap;
    align-items: flex-start;
  }

  .profile-wrapper {
    width: 392px;
    min-width: 392px;
    height: auto;
    box-shadow: 5px 5px 15px #3754aa1a;
    border-radius: 25px;
    background-color: $biletum-white;
    z-index: 1;
    margin-right: 16px;
  }

  .meetings-page .profile-wrapper {
    width: 100%;
  }

  .field-block {
    padding: 10px 0;
    position: relative;
    overflow: hidden;

    &-documents {
      padding-left: 25px;
      padding-right: 25px;
    }

    .field-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 25px 8px;

      .field-title {
        text-align: left;
        font-family: Point, sans-serif;
        font-weight: 600;
        font-size: 13px;
        letter-spacing: 0;
        color: $biletum-black;
        opacity: 0.8;
        position: relative;

        &::before {
          display: block;
          position: absolute;
          left: -300%;
          bottom: 2px;
          content: "";
          border-bottom: 1px solid #eaeaea;
          width: 300%;
        }
      }

      .btn {
        text-align: left;
        font-family: Point, "Point Regular", sans-serif;
        font-size: 10px;
        color: $biletum-light-blue;
      }
    }

    .enumerable {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-flow: row wrap;
      overflow: hidden;
      height: 100px;
      flex: 1;
    }
  }

  .flex-column-center {
    display: flex;
    justify-content: center;
    flex-flow: column wrap;
    align-items: center;
  }

  .field-content {
    padding: 16px 0;

    &-meetings {
      box-shadow: none;
      border-radius: 0;
      padding: 25px;
      background-color: transparent;
    }

    .doc {
      .doc-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 33px;
      }
    }

    .square-radius {
      border-radius: 5px;
    }

    .round-radius {
      border-radius: 50%;
    }

    .avatar {
      width: 60px;
      height: 60px;
      min-width: 60px;
      position: relative;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    .avatar-documenticon {
      width: 37px;
      height: 37px;
      min-width: 37px;
    }

    .title {
      margin-top: 6px;
    }

    .title,
    .subtitle {
      text-align: left;
      font-family: Point, sans-serif;
      font-weight: 300;
      font-size: 8px;
      color: $biletum-black;
      opacity: 0.8;
    }
  }

  .profile {
    padding: 0 25px;
    margin-bottom: 2rem;

    &::before {
      display: block;
      content: "";
      height: 1px;
      width: 25px;
      background-color: $biletum-light-grey;
      position: relative;
      top: 0;
      left: -25px;
    }

    .head-block {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .square-radius {
        border-radius: 5px;
      }

      .round-radius {
        border-radius: 50%;
      }

      .avatar {
        width: 60px;
        height: 60px;
        min-width: 60px;
        opacity: 1;
        position: relative;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .head-content {
        padding-left: 26px;
        align-self: flex-start;

        .title {
          text-align: left;
          font-size: 15px;
          font-family: Point, sans-serif;
          font-weight: bold;
          letter-spacing: 0;
          color: $biletum-black;
        }

        .subtitle {
          text-align: left;
          font-family: Point, sans-serif;
          font-size: 12px;
          letter-spacing: 0;
          color: $biletum-black;
        }

        .info {
          text-align: left;
          font-size: 11px;
          font-family: Point, sans-serif;
          letter-spacing: 0;
          color: $biletum-black;
          padding-top: 3px;
        }
      }
    }

    .nav {
      display: flex;
      justify-content: space-between;
      padding: 25px 0 0;

      .tab {
        text-align: left;
        font-family: Point, sans-serif;
        font-size: 13px;
        font-weight: 600;
        opacity: 0.8;
        color: $biletum-black;
        text-decoration: none;
      }

      .highlight {
        border-bottom: 1px solid $biletum-light-blue;
        color: $biletum-light-blue;
      }
    }

    .content {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      .description {
        font-family: Point, sans-serif;
        font-size: 12px;
        text-align: left;
      }
    }
  }

  html[lang=ru] .nav a {
    font-size: 12px;
  }

  .content-item {
    margin-bottom: 26px;
    width: 20%;

    &:last-child {
      margin-right: 0;
    }
  }

  .show-all {
    height: auto !important;
  }

  .fav {
    margin-left: auto;
  }

  .field-block-notes {
    padding-left: 25px;
    padding-right: 25px;
  }

  .profile-wrapper-skeleton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
  }

  .inner-circular-progress {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .messages {
    padding: 0 25px;
  }
</style>

<style lang="scss">
  .read-more {
    font-size: 10px;
    font-family: Point, sans-serif;
    color: #00b6f8 !important;
    text-decoration: none;
    cursor: pointer;
  }

  .more-btn {
    text-align: left;
    font-family: Point, sans-serif;
    font-size: 8px;
    color: #00b6f8 !important;
  }

</style>
